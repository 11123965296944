// Font families
//------------------------------------------------------------------------------

@mixin sentinel {
  $fallback: 'Georgia', 'Times New Roman', serif;
  $real: 'Sentinel', 'Sentinel 4r', 'Sentinel A', 'Sentinel B';
  font-family: $fallback;
  font-weight: 400;

  .fontsactive & {
    font-family: $real, $fallback;
  }

  em {
    @include sentinel-italic;
  }
}

@mixin sentinel-italic {
  $fallback: 'Georgia', 'Times New Roman', serif;
  $real: 'Sentinel', 'Sentinel 4i', 'Sentinel A', 'Sentinel B';
  font-family: $fallback;
  font-style: italic;

  .fontsactive & {
    font-family: $real, $fallback;
  }
}

@mixin sentinel-bold {
  $fallback: 'Georgia', 'Times New Roman', serif;
  $real: 'Sentinel', 'Sentinel 6r', 'Sentinel A', 'Sentinel B';
  font-family: $fallback;
  font-weight: 600;

  .fontsactive & {
    font-family: $real, $fallback;
  }
}

@mixin trade-gothic-condensed {
  $fallback: 'HelveticaNeue-CondensedBold', 'Arial Nova Condensed Bold', 'Arial Narrow', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  $real: 'Trade Gothic Condensed';
  font-family: $fallback;
  font-weight: 700;

  .fontsactive & {
    font-family: $real, $fallback;
  }
}

@mixin trade-gothic-new {
  $fallback: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  $real: 'Trade Gothic New';
  font-family: $fallback;
  font-weight: 700;

  .fontsactive & {
    font-family: $real, $fallback;
  }
}


// Base
//------------------------------------------------------------------------------

body,
button,
select,
input,
textarea {
  @include sentinel;
  @include font-smoothing(on);
  line-height: $base-line-height;
  text-rendering: optimizeLegibility;
}


// small
//------------------------------------------------------------------------------

@mixin small {
  font-size: 87.5%;
}

small,
.small {
  @include small;
}


// Links
//------------------------------------------------------------------------------

%link {
  cursor: pointer;
}

%link--styled {
  @extend %link;
  @include sentinel-bold;
  @include transition-simple(color);
  font-weight: 600;
  color: $accent-primary;

  &:hover,
  &:focus {
    color: $accent-secondary;
  }
}

button,
.pseudo-link {
  @extend %link;
}

a,
.pseudo-link--styled {
  @extend %link--styled;
}

.link--secondary {
  color: $dark;
}


// Headings
//------------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: 400;
}

@mixin heading--level-1 {
  @include trade-gothic-condensed;
  @include rem((font-size: 60px));
  line-height: 1.2;
  text-transform: uppercase;
  color: $dark;

  @media (max-width: em(600px)) {
    @include rem((font-size: 55px));
  }

  @media (max-width: em(400px)) {
    @include rem((font-size: 45px));
  }

  .theme--dark & {
    color: #fff;
  }
}

@mixin heading--level-2 {
  @include trade-gothic-condensed;
  @include rem((font-size: 30px));
  line-height: 1.275;
  text-transform: uppercase;
  color: $dark;

  .theme--dark & {
    color: #fff;
  }
}

@mixin heading--level-3 {
  @include trade-gothic-condensed;
  @include rem((font-size: 24px));
  line-height: 1.3;
  text-transform: uppercase;
  color: $dark;

  .theme--dark & {
    color: #fff;
  }
}
