@mixin size-block($size) {
  @if $size == small {
    $spacing-y: 28px !global;
  } @else if $size == medium {
    $spacing-y: 42px !global;
  }

  $block-spacing-y: $spacing-y * 0.65 !global;
  @content;

  @media #{$medium-up} {
    $block-spacing-y: $spacing-y * 0.75 !global;
    @content;
  }

  @media #{$large-up} {
    $block-spacing-y: $spacing-y * 0.825 !global;
    @content;
  }

  @media only screen and (min-width: em(1280px)) {
    $block-padding-y: $spacing-y !global;
    @content;
  }
}

%block {
  &:first-child:not(.block--top) {
    padding-top: 0;
  }

  &:last-child:not(.block--bottom) {
    padding-bottom: 0;
  }
}

.block--small {
  @extend %block;

  @include size-block(small) {
    @include rem((
      padding-top: $block-spacing-y,
      padding-bottom: $block-spacing-y
    ));
  }
}

.block--no-top {
  padding-top: 0;
}

.block--no-bottom {
  padding-bottom: 0;
}
