.button {
  $padding-x: 3em;
  @include appearance(none);
  @include trade-gothic-new;
  @include rem((font-size: 17px));
  @include transition-simple(background-color);
  display: inline-block;
  padding: 0.65em $padding-x 0.72em;
  color: #fff;
  background-color: $accent-primary;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.086em;

  &:hover,
  &:focus,
  %link:hover &,
  %link:focus & {
    background-color: $dark;
    color: #fff;

    .theme--dark & {
      background-color: darken($accent-primary, 8%);
    }

    &.button--invert {
      background-color: transparent;
      color: $dark;
    }
  }
}

.button--full {
  width: 100%;
  padding-right: 2em;
  padding-left: 2em;
}

.button--secondary {
  background-color: $accent-secondary;
}

.button--invert {
  @include transition-simple(color);
  background-color: transparent;
  color: $accent-primary;
}

.button--light {
  @include transition-simple((background-color, color));
  background-color: $xlight;
  color: $accent-secondary;

  &:hover,
  &:focus,
  %link:hover &,
  %link:focus & {
    background-color: $accent-secondary;
    color: #fff;
  }
}

.button__icon {
  top: -0.15em;
  margin-left: 0.15em;
  vertical-align: middle;

  &.icon {
    width: 1em;
  }

  &.icon--ui__plus {
    @include rem((width: 13px));
    margin-top: 1px;
  }
}
