.section {
  position: relative;
  z-index: 10;

  + .section {
    box-shadow: 0 -1px 0 $light;
  }

  &.section--before-no-divide + .section {
    box-shadow: none;
  }
}
