// Intrinsic aspect ratio sizing
//------------------------------------------------------------------------------

.aspect-ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.aspect-ratio__media {
  position: absolute;
  z-index: 10;
  top: -1px;
  left: -1px;
  width: 101.5%;
  max-width: none;
}


// Organised media
//------------------------------------------------------------------------------

$media-list-gap: 12px;

.media__list {
  overflow: hidden;
}

.media__list__outer {
  @include rem((
    padding-top: $type-alignment-compensation,
    padding-bottom: $type-alignment-compensation
  ));
}

.media__list__inner {
  @include rem((
    margin-top: -$media-list-gap,
    margin-left: -$media-list-gap
  ));
  font-size: 0;
}

.media__list__item {
  @include rem((
    padding-top: $media-list-gap,
    padding-left: $media-list-gap
  ));
  display: inline-block;
}

.media__list__link {
  display: block;

  &:before {
    content: '';
    display: block;
    padding-top: 62.5%;
  }
}

.media__list__image {
  width: 100%;
}

.media__list__text {
  @include rem((font-size: 17px));
  margin-top: 0.7em;
  color: $mid;
}
