html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: percentage(($base-font-size - 2px) / 16px);

  @media only screen and (min-width: em(1200px)) {
    font-size: percentage(($base-font-size - 1px) / 16px);
  }

  @media only screen and (min-width: em(1430px)) {
    font-size: percentage($base-font-size / 16px);
  }
}

body {
  min-height: 100%;
}

.u-pageTopBar {
  @include clearfix;
  position: relative;
  z-index: 30;
  padding: 8px;
  background-color: #f2f3f4;
  box-shadow: 0 1px 0 0 rgba($dark, 0.13);

  @media (min-width: 780px) {
    display: none;
  }
}

.u-pageTopBar__inner {
  max-width: $max-page-width;
  margin-right: auto;
  margin-left: auto;
}

.oat-reciteme-toggle {
  width: 173px;
  padding: 0;
  background: none;
  border: 0;
  border-radius: 0;
  transition: opacity 100ms ease;

  .u-pageTopBar & {
    float: right;
  }

  .page__header & {
    @include rem((top: 14px));
    position: absolute;

    @media (max-width: 779px) {
      display: none;
    }
  }

  &:hover {
    opacity: 0.7;

    @supports (-webkit-filter: grayscale(1)) or (filter: grayscale(1)) {
      opacity: 1;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }
}
