.focus {
  img {
    width: 100%;
  }

  ul,
  ol {
    padding-left: 23px;
  }

  ul li {
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }
}
