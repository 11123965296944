.twitter-typeahead {
  width: 100%;
}

.tt-hint {
  height: 100%;
  color: rgba(#767e84, 0.8);
}

.tt-menu {
  right: 0;
  width: 100%;
  max-height: calc(100vh - 300px);
  margin-top: 10px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 3px;
  box-shadow:
    0 0 0 1px rgba($dark, 0.1),
    0 4px 20px rgba($dark, 0.15);

  @media #{$mq-sidebar} {
    min-width: 400px;
  }

  @media #{$mq-inline-header} {
    right: -16px;
    left: auto !important;
    width: calc(100% + 32px);
  }

  @media #{$xxlarge-up} {
    left: 0 !important;
    right: auto;
    max-width: 600px;
  }
}

.tt-suggestion {
  @include transition-simple(background-color);
  @include rem((font-size: $base-font-size - 1px));
  border: none;
  border-bottom: 1px solid rgba($dark, 0.12);

  .search__result__link {
    @include transition-simple(text-decoration-color);

    @supports (text-decoration-color: transparent) {
      text-decoration: underline;
      text-decoration-skip-ink: auto;
      text-decoration-color: transparent;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &.tt-cursor,
  &:first-child:last-child,
  &:hover,
  &:focus {
    background-color: #f2f3f4;

    .search__result__link {
      text-decoration: underline;
      text-decoration-skip-ink: auto;
      text-decoration-color: $dark;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.tt-highlight {
  color: $accent-secondary;
}
