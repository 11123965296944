.flag {
  position: relative;
  background-color: $accent-primary;
  color: #fff;

  &:after {
    @include rem((height: $bar-height));
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    background-color: $accent-primary;

    @media #{$mq-no-inline-header} {
      @include rem((height: 100px)); // Any value large enough to overflow out of page top
    }
  }

  &.button {
    &:after {
      @include transition-simple(background-color);
    }

    &:hover,
    &:focus,
    %link:hover &,
    %link:focus & {
      &:after {
        background-color: $dark;
      }
    }
  }
}

.flag--secondary {
  &,
  &:after {
    background-color: $accent-secondary;
  }
}
