.toggle {


  &.is-open {}
}

.toggle__content {
  display: none;

  .toggle.is-open & {
    display: block;
  }
}

.toggle__trigger {


  .toggle.is-open & {
    display: none;
  }
}
