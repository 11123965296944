.video {
  position: relative;
}

.video__video {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;

  &::before {
    display: block;
    content: '';
    padding-bottom: percentage(603 / 1077);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
