.donate__button {
  position: relative;
  box-sizing: content-box;
  padding-right: 0;
  padding-left: 1px;
  left: -1px;
}

.donate__button--mobile__wrap {

  .fmt & {
    margin-top: -$fmt-spacing-p;
  }
}

.donate__button--mobile {
  padding-right: 0;
  padding-left: 0;
  border-radius: 1px;

  @media #{$mq-sidebar} {
    display: none;
  }
}

.search__input {
  @include no-default-form-control-styling;
  @include rem((font-size: 26px));
  @include transition-simple(border-color);
  position: relative;
  display: block;
  width: 100%;
  padding: 0.22em 0.6em;
  background-color: $xlight;
  border-radius: 2px;
  border: 2px solid $light;

  @media #{$large-up} {
    @include rem((font-size: 28px));
  }

  @media #{$xlarge-up} {
    @include rem((font-size: 30px));
  }

  @media #{$xxlarge-up} {
    @include rem((font-size: 32px));
  }

  &:focus {
    border-color: $accent-secondary;
  }
}

.search__result {
  border-top: 1px solid rgba(#fff, 0.1);

  &:first-child {
    border-top: none;
  }
}

.search__result__link {
  @include table-center-parent;
  @include rem((padding: 12px 12px 12px 18px));
  color: $dark;
}

.search__result__content {
  @include table-center;
  padding-right: 0.8em;
}

.search__result__heading {
  @include heading--level-3;
}

.search__result__media {
  @include table-center;
  @include rem((width: 65px));
  background-color: $placeholder-on-dark;

  .aspect-ratio {
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.search__results__none {
  @include heading--level-3;
  @include rem((padding: 20px));
}

.search__submit {
  @include visually-hidden;
}
