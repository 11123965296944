// Foundation grid
//------------------------------------------------------------------------------

$include-html-global-classes: false; // Prevent Foundation outputting its general styles
$include-xl-html-grid-classes: true; // Make xlarge + xxlarge grid classes available
$rem-base: $base-font-size; // Set rem base to that specced in vars

// Settings
$row-width: 100%;
$column-gutter: 0;
$total-columns: 12;

// Default foundation MQs - uncomment & alter if changes are needed:
// $small-range: (0em, 40em);
// $medium-range: (40.063em, 64em);
// $large-range: (64.063em, 90em);
// $xlarge-range: (90.063em, 120em);
// $xxlarge-range: (120.063em, 99999999em);

// Set default .grid__item width before Foundation rules (to avoid overriding)
.grid__item {
  width: 100%;
}

@import 'foundation/foundation/components/grid'; // Output grid


// Spaced grids
//------------------------------------------------------------------------------

.grid {
  font-size: 0;
  text-align: left;
}

.grid__item {
  display: inline-block;
  font-size: 1rem;
  text-align: left;
  vertical-align: top;
}


// Breakpoints
//------------------------------------------------------------------------------

// For the Coffescript module Breakpoint
$breakpoint-list: ('small');
$breakpoint-map: (
  medium:  #{lower-bound($medium-range)},
  large:   #{lower-bound($large-range)},
  xlarge:  #{lower-bound($xlarge-range)}
);

body:before,
body:after {
  @include visually-hidden;
  content: 'small';
}

@each $breakpoint-name, $breakpoint-value in $breakpoint-map {
  @media only screen and (min-width:#{$breakpoint-value}) {
    body:before {
      content: '#{$breakpoint-name}';
    }
  }
  $breakpoint-list: append($breakpoint-list, $breakpoint-name, space);
}

body:after {
  content: '#{$breakpoint-list}';
}
