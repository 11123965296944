.bar {
  @include rem((
    top: -$bar-height,
    height: $bar-height
  ));
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 30; // Above .page__sidebar
  background-color: $dark;
  opacity: 0.25;
}
