// Vertical spacing
//------------------------------------------------------------------------------

@mixin fmt-margin-y($spacing-top, $spacing-bottom: $spacing-top) {
  .fmt & {
    margin-top: $spacing-top;
    margin-bottom: $spacing-bottom;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

$fmt-spacing-p: 0.9em;

p,
ul,
ol,
.fmt__space {
  @include fmt-margin-y($fmt-spacing-p);
}

h1,
h2 {
  @include fmt-margin-y(0.65em, 0.425em);
}

hr {
  @include fmt-margin-y(1.45em);
}

figure,
img,
video,
iframe,
.fmt__media {
  @include fmt-margin-y(2em);
}


// Formatted content rules
//------------------------------------------------------------------------------

.fmt {
  p > .button {
    &:first-child {
      @include rem((margin-top: $type-alignment-compensation));
    }

    &:last-child {
      @include rem((margin-bottom: $type-alignment-compensation));
    }
  }

  ul,
  ol {
    line-height: 1.75;
  }

  h1,
  h2,
  h3,
  h4 {
    + {
      p,
      ul,
      ol {
        margin-top: 0;
      }
    }
  }

  h1,
  h2 {
    @include heading--level-2;

    &:first-child {
      @include rem((margin-top: 2px));
    }
  }
}

.fmt--center {
  text-align: center;
}
