.timeline {
  @include clearfix;
  position: fixed;
  z-index: 30;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e7e8e8;
  border-top: 1px solid #fff;
}

.snap-timeline {
  display: block;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;

  text {
    user-select: none;
  }
}
