$poster-button-height: 55px;

.posters {
  @include clearfix;
  z-index: 10;

  // Hack to hide content on detail pages when timeline is used to navigate to year with productions/actors.
  &:not(:empty) ~ .content {
    display: none;
  }
}

.posters__heading {
  @include heading--level-2;
  @include rem((
    margin-top: 5px,
    font-size: 42px
  ));
  line-height: 1;
}

.poster {
  position: relative;
  float: left;
  overflow: hidden;
}

.poster__link {
  @include transition-simple(box-shadow);
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  padding-top: 100%;
  border-radius: 2px;

  &::before {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba($dark, 0.15);
    border-radius: inherit;
    pointer-events: none;
    content: '';
  }
}

.poster__media {
  $offset: -4px;
  @include rem((
    top: $offset,
    right: $offset,
    bottom: $offset,
    left: $offset
  ));
  position: absolute;
  z-index: 10;
  background-color: $dark;

  @supports(object-fit: cover) {
    @include rem((bottom: 55px));
  }

  .modernizr-no-touchevents & {
    @include transition-pop-blur;
  }

  .modernizr-no-touchevents .poster__link:hover &,
  .modernizr-no-touchevents .poster__link:focus &,
  .modernizr-no-touchevents .poster__link:active & {
    @include transition-pop-focus;
    transform: scale(1.025);
  }
}

.poster__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;

  .modernizr-no-touchevents & {
    will-change: transform;
  }
}

.poster__image--bw {



  .modernizr-no-touchevents & {
    z-index: 10;
    opacity: 0;
  }

  .modernizr-no-touchevents.modernizr-cssfilters &,
  .modernizr-no-touchevents .lte-ie9 & {
    opacity: 1;
  }

  .modernizr-touchevents & {
    display: none;
  }
}

.poster__media .grayscale-replaced {
  @extend .poster__image--bw;
  opacity: 1 !important;

  &,
  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.poster__image--color {


  .modernizr-no-touchevents & {
    @include transition-pop-blur(opacity);
    z-index: 20;
    opacity: 0;
  }

  .modernizr-no-touchevents .poster__link:hover &,
  .modernizr-no-touchevents .poster__link:focus &,
  .modernizr-no-touchevents .poster__link:active & {
    @include transition-pop-focus;
    opacity: 1;
  }
}

.poster__content {
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $bg;

  .modernizr-touchevents & {
    opacity: 1;
  }

  .modernizr-no-touchevents & {
    @include transition-pop-blur((opacity, transform));
    transform: translateY(rem($poster-button-height));
  }

  .modernizr-no-touchevents .poster__link:hover &,
  .modernizr-no-touchevents .poster__link:focus &,
  .modernizr-no-touchevents .poster__link:active & {
    @include transition-pop-focus;
    opacity: 1;
    transform: translateY(0);
  }
}

.poster__content__section {
  @include rem((
    padding-top: 12px,
    padding-bottom: 13px
  ));

  @media #{$mq-sidebar} {
    @include rem((
      padding-right: 18px,
      padding-left: 18px
    ));
  }
}

.poster__heading {
  @include heading--level-3;
  @include rem((margin-bottom: -1px));
}

.poster__button {
  @include rem((height: $poster-button-height));
  padding-top: 0.75em;

  .modernizr-touchevents & {
    opacity: 1;
  }

  .modernizr-touchevents .poster__link:hover &,
  .modernizr-touchevents .poster__link:focus &,
  .modernizr-touchevents .poster__link:active & {
    background-color: $accent-primary;
  }

  .modernizr-no-touchevents & {
    @include transition-pop-blur(opacity);
    opacity: 0;
  }

  .modernizr-no-touchevents .poster__link:hover &,
  .modernizr-no-touchevents .poster__link:focus &,
  .modernizr-no-touchevents .poster__link:active & {
    @include transition-pop-focus;
    background-color: $accent-primary;
    opacity: 1;
  }
}

.posters__overlay {
  position: fixed;
  z-index: 20;
  left: 0;
  display: none;
  overflow: hidden;
  background-color: rgba($bg, 0.9);
  opacity: 0;
}

.posters__overlay__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.posters__empty {
  @include vertical-center-parent;
  position: relative;
  z-index: 10;
  display: none;
  text-align: center;

  // Hack to hide content on detail pages when timeline is used to navigate to a blank year.
  &[style*='block'] + .content {
    display: none;
  }
}

.posters__empty__inner {
  @include vertical-center;
}
