.cast {}

.cast__item {
  border-top: 1px solid $light;

  .cast:first-child &:first-child {
    border-top: none;
  }
}

.cast__toggle {}

.cast__link {
  @include rem((
    padding-top: 14px,
    padding-bottom: 14px
  ));

  .cast:first-child .cast__item:first-child & {
    padding-top: 0;
  }

  .content__group:last-child .cast:last-child .cast__item:last-child & {
    padding-bottom: 0;
  }
}

.cast__link {
  @include table-center-parent;
}

.cast__content {
  @include table-center;
  @include rem((padding-right: 20px));
}

.cast__heading {
  font-weight: 600;
}

.cast__info {
  margin-bottom: -0.075em;
  font-weight: 400;
  font-style: italic;
  color: $mid;
}

.cast__media {
  @include table-center;
  @include rem((width: 110px));

  .aspect-ratio {
    &:before {
      content: '';
      display: block;
      padding-top: 62.5%;
    }
  }
}

.cast__toggle {
  @include rem((margin-bottom: $type-alignment-compensation));
}
