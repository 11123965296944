// Type
//------------------------------------------------------------------------------

$base-font-size: 19px; // Used to set intial rem scale in utilities/units
$base-line-height: 1.6;
$type-alignment-compensation: 9px;
$max-content-width: 900px;


// Colors
//------------------------------------------------------------------------------

$accent-primary: #cb2e33;
$accent-secondary: #149cc3;
$dark: #0c1619;
$mid: #767e84;
$light: rgba($dark, 0.115);
$xlight: #f4f5f7;
$body: #2b2e30;
$bg: #fff;
$placeholder-on-dark: lighten($dark, 5%);


// Layout
//------------------------------------------------------------------------------

$centered-grid-max-width: 1200px;
$max-page-width: 1600px;
$bar-height: 0px;
$min-poster-width: 330px;
$min-thumb-width: 110px;
$poster-gap: 32px;

$mq-sidebar-px: 1120; // MQ min-width in px at which the sidebar is shown
$mq-sidebar: "only screen and (min-width: #{$mq-sidebar-px / 16}em)";
$mq-no-sidebar: "only screen and (max-width: #{($mq-sidebar-px - 1) / 16}em)";

$mq-inline-social-px: 450; // MQ min-width in px at which all header controls are shown in same row
$mq-inline-social: "only screen and (min-width: #{$mq-inline-social-px / 16}em)";
$mq-no-inline-social: "only screen and (max-width: #{($mq-inline-social-px - 1) / 16}em)";

$mq-inline-header-px: 760; // MQ min-width in px at which all header controls are shown in same row
$mq-inline-header: "only screen and (min-width: #{$mq-inline-header-px / 16}em)";
$mq-no-inline-header: "only screen and (max-width: #{($mq-inline-header-px - 1) / 16}em)";

$mq-content-columns-px: 590; // MQ min-width in px at which actors/productions use columns for top section
$mq-content-columns: "only screen and (min-width: #{$mq-content-columns-px / 16}em)";
$mq-no-content-columns: "only screen and (max-width: #{($mq-content-columns-px - 1) / 16}em)";
