	.icon--ui__chevron-down {
		@include rem((width: 32px));

		&:before {
			padding-top: calc((22% / 32%) * 100%);
		}
	}
	.icon--ui__chevron-right {
		@include rem((width: 21px));

		&:before {
			padding-top: calc((31% / 21%) * 100%);
		}
	}
	.icon--ui__close {
		@include rem((width: 20px));

		&:before {
			padding-top: calc((20% / 20%) * 100%);
		}
	}
	.icon--ui__fb {
		@include rem((width: 43px));

		&:before {
			padding-top: calc((43% / 43%) * 100%);
		}
	}
	.icon--ui__ig {
		@include rem((width: 65.18px));

		&:before {
			padding-top: calc((65.18% / 65.18%) * 100%);
		}
	}
	.icon--ui__plus {
		@include rem((width: 13px));

		&:before {
			padding-top: calc((13% / 13%) * 100%);
		}
	}
	.icon--ui__search {
		@include rem((width: 17px));

		&:before {
			padding-top: calc((17% / 17%) * 100%);
		}
	}
	.icon--ui__star {
		@include rem((width: 23px));

		&:before {
			padding-top: calc((23% / 23%) * 100%);
		}
	}
	.icon--ui__tw {
		@include rem((width: 43px));

		&:before {
			padding-top: calc((43% / 43%) * 100%);
		}
	}
