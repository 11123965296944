$pswp__assets-path: "/media/images/photoswipe/default-skin/";
$pswp__background-color: rgba($dark, 0.98);
$pswp__placeholder-color: $placeholder-on-dark;
$pswp__error-text-color: $xlight; // "Image not loaded" text color

.lightbox {
  @include component;
}

@import "photoswipe/src/css/main";
@import "photoswipe/src/css/default-skin/default-skin";

.pswp__caption__center {
  @include sentinel-italic;
  @include small;
  max-width: none;
  text-align: center;
  line-height: $base-line-height;
}
