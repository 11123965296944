html {
  background-color: $dark;
  -webkit-tap-highlight-color: rgba(desaturate($accent-secondary, 5%), 0.1);

  @include selection {
    color: $dark;
    text-shadow: none;
    background-color: desaturate(lighten($accent-secondary, 51%), 5%);
  }
}

body,
button,
select,
input,
textarea {
  color: $body;
}

em {
  color: $mid;
}

hr {
  border: none;
  border-top: 1px solid $light;
}

@include placeholder {
  color: rgba(#767e84, 0.8);
  opacity: 1;
}


// Dark
//------------------------------------------------------------------------------

.theme--dark {
  background-color: $dark;
  color: #fff;
}
