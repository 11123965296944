.soc {
  @include rem((top: 5px));
  position: absolute;
  font-size: 0;
  text-align: right;

  @media #{$mq-inline-social} {
    direction: rtl;

    br {
      display: none;
    }
  }
}

.soc__item {
  display: inline-block;
  vertical-align: middle;

  .icon {
    @include rem((width: 20px));
    display: block;
  }
}


// Scale
//------------------------------------------------------------------------------

@mixin size-social($padding) {
  .soc {
    @include rem((margin-right: -$padding));
  }

  .soc__item {
    @include rem((padding: $padding));
  }
}

@include size-social(8px);

@media #{$mq-inline-social} {
  @include size-social(10px);
}
