.icon {
  position: relative;
  display: inline-block;
  max-width: 100%;

  // Top padding is applied to :before in Sass files generated by gulp sprites
  // task, providing an intrinsic aspect ratio box in which to position the SVG
  // @see sprites/tmpl.scss for the Sass template used for the generation
  &:before {
    display: block;
    content: '';
  }

  // Transparent layer above SVG to prevent event bubbling/delegation issues
  // when clicking an icon
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  // Position SVG absolutely inside intrinsic aspect ratio box
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Example import (assumes a sprite named 'ui'):
@import 'ui/sprite.scss';
