.reviews {}

.review {
  @include clearfix;
  @include rem((margin-top: 20px));

  &:first-child {
    margin-top: 0;
  }
}

.review__heading {
  @include sentinel-bold;
  @include rem((font-size: 24px));
  float: left;
  margin-bottom: 0.45em;
}

.review__rating {
  @include rem((margin-top: 7px));
  float: right;
  font-size: 0;
}

.review__icon {
  @include rem((margin-left: 5px));
  color: $accent-primary;

  &:first-child {
    margin-left: 0;
  }
}

.review__content {
  @include sentinel-italic;
  float: left;
  width: 100%;
}
