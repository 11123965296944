@mixin transition-pop-blur($property: transform, $duration: 295ms) {
  transition-property: implode($property, ', ');
  transition-timing-function: ease-in-out;
  transition-duration: $duration;
}

// $bounce 1 for no bounce, $bounce > 1 for bounce
@mixin transition-pop-focus($bounce: 1, $duration: 350ms) {
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, $bounce);
  transition-duration: $duration;
}

@mixin transition-simple($property, $ease: linear, $duration: 150ms) {
  transition-property: implode($property, ', ');
  transition-duration: $duration;
  transition-timing-function: $ease;
}
