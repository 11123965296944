.page__wrap {
  @include rem((
    max-width: $max-page-width,
    padding-top: $bar-height
  ));
  position: relative;
  z-index: 20;
  overflow: hidden; // Prevent #posters-overlay spilling out horizontally on wide screens
  margin-right: auto;
  margin-left: auto;
}

.page {
  background-color: $bg;
  border-top-left-radius: 1px;
}

.page__inner {
  @include clearfix;
  position: relative;
}

.page__main {
  position: relative;
  z-index: 10;

  @media #{$mq-sidebar} {
    float: left;
    width: 100%;
  }
}

.page__sidebar {
  color: $mid;

  @media #{$mq-no-sidebar} {
    position: relative;
    box-shadow: 0 -1px 0 rgba($dark, 0.09);

    @include size-block(medium) {
      @include rem((padding-top: $block-spacing-y));
    }
  }

  @media #{$mq-sidebar} {
    float: left;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 20;
    top: 0;
    width: 1px;
    height: 100%;
    margin-left: -1px;
    background-color: $light;
  }
}

.page__sidebar__inner {}

.page__sidebar__upper {
  display: none;

  @media #{$mq-sidebar} {
    position: relative;
    z-index: 20; // Above .page__main
    display: block;
  }
}

.page__sidebar__content {
  @media #{$mq-no-sidebar} {
    @include clearfix;
  }
}

.page__sidebar__section {
  @include rem((font-size: 17px));

  @media #{$mq-no-sidebar} {
    @media (min-width: em(800px)) {
      width: percentage(1 / 3);
      float: left;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none !important;
    }
  }
}

.page__sidebar__legal {
  @media #{$mq-no-sidebar} {
    margin-bottom: 5px; // Compensate for timeline dot size

    @media (min-width: em(800px)) {
      @include rem((
        padding-top: 27px,
        padding-bottom: 28px
      ));
      width: 100%;
      border-top: 1px solid $light;

      @include size-block(medium) {
        @include rem((margin-top: $block-spacing-y));
      }
    }
  }
}

.page__header {
  position: relative;
  z-index: 20;

  @media #{$mq-no-inline-header} {
    font-size: 0;
  }

  @media #{$mq-inline-header} {
    @include table-center-parent;
  }
}

.page__header__content {
  position: relative;
  width: 100%;

  @media #{$mq-inline-header} {
    @include table-center;
    vertical-align: bottom;
  }
}

.page__header__link,
.page__flag {
  @include rem((padding-bottom: 2px));
}

.page__header__link {


  &:after {
    @include transition-simple(background-color);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $light;
  }

  &:hover,
  &:focus {
    &:after {
      opacity: 1;
      background-color: $accent-secondary;
    }
  }
}

.page__header__link {
  @include heading--level-1;
  position: relative;
  display: block;

  @media #{$mq-no-inline-header} {
    @include heading--level-2;
    @include rem((margin-top: 28px));
  }

  .icon {
    width: 0.35em;
  }
}

.page__header__link__pre {
  @media #{$mq-inline-header} {
    display: none;
  }
}

.page__header__link__text {
  vertical-align: middle;
}

.page__header__link__icon {
  vertical-align: middle;
}

.page__flag {
  @include transition-simple((color, background-color));
  @include heading--level-1;
  @include rem((
    padding-right: 16px,
    padding-left: 16px
  ));
  position: relative;
  vertical-align: bottom;
  color: #fff;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;

  @media #{$mq-no-inline-header} {
    display: inline-block;
  }

  @media #{$mq-inline-header} {
    @include table-center(false);
  }

  &:hover,
  &:focus {
    color: #fff;
    background-color: $dark;

    &:after {
      background-color: $dark;
    }
  }

  &:after {
    @include transition-simple(background-color);
  }
}

.page__flag__pre {
  @media (max-width: em(568px)) {
    display: none;
  }
}

.page__logo {
  position: relative;
  vertical-align: bottom;
  box-sizing: content-box;

  @media #{$mq-no-inline-header} {
    display: inline-block;
  }

  @media #{$mq-inline-header} {
    @include table-center(false);
  }
}

.page__logo__image {
  max-width: none;
}

.page__content {
  position: relative;
  z-index: 10;
}

.page__content__inner {
  position: relative;
}

.poster {
  width: 100%;
}


// Scale
//------------------------------------------------------------------------------

@mixin size-thing($thing-width, $offset: 0, $multiplier: 1) {
  @for $i from 1 through floor(($max-page-width - $offset) / $thing-width) { // Loop iterations needed to output @media only screen and rules for max target screen width
    $mq: em($offset + ($i * ($thing-width * $multiplier)));

    @media only screen and (min-width: $mq) {
      width: clamp(100% / $i, 0%, 100%);
    }
  }
}

@mixin size-page($sidebar-width, $logo-width, $gap-width, $content-media-width) {
  $content-padding: $gap-width * 0.8;

  .page__main__inner {
    @media #{$mq-sidebar} {
      @include rem((margin-right: $sidebar-width));
    }
  }

  .page__sidebar {
    @media #{$mq-sidebar} {
      @include rem((
        width: $sidebar-width,
        margin-left: -$sidebar-width
      ));
    }
  }

  .page__sidebar__content {
    @media #{$mq-no-sidebar} {
      @include rem((
        padding-right: $gap-width / 2,
        padding-left: $gap-width / 2
      ));
    }

    @media #{$mq-sidebar} {
      @include rem((
        padding-top: $content-padding,
        padding-right: $gap-width,
        padding-left: $gap-width
      ));
    }
  }

  .page__sidebar__section {
    @media #{$mq-no-sidebar} {
      @include rem((
        padding-right: $gap-width / 2,
        padding-left: $gap-width / 2
      ));
    }
  }

  .page__header__content {
    @include rem((
      padding-right: $gap-width,
      padding-left: $gap-width
    ));
  }

  .page__header__content .oat-reciteme-toggle {
    @include rem((left: $gap-width));
  }

  .page__logo {
    @include rem((
      padding-top: $content-padding - $bar-height,
      padding-right: $gap-width,
      padding-left: $gap-width
    ));
  }

  .page__logo__image {
    @include rem((width: $logo-width));
  }

  .page__content,
  .search {
    @include rem((padding-top: $content-padding));
  }

  .content__heading + .content__upper {
    @media #{$mq-content-columns} {
      @include rem((margin-top: $content-padding));
    }
  }

  .posters__empty__inner {
    @include rem((padding-bottom: $content-padding));
  }

  .spinner {
    @include rem((margin-top: -($content-padding / 2)));
  }

  .soc {
    @include rem((right: $gap-width));
  }

  .posters {
    @include rem((
      padding: $gap-width - $content-padding / 2,
      margin-top: -($content-padding / 2)
    ));
    padding-top: 0;
  }

  .posters__heading {
    @include rem((padding: $content-padding / 2));
  }

  .poster {
    @include rem((padding: $content-padding / 2));
    @include size-thing($min-poster-width);

    @media #{$mq-sidebar} {
      @include size-thing($min-poster-width, $sidebar-width);
    }
  }

  .poster__content__section {
    @media #{$mq-no-sidebar} {
      @include rem((
        padding-right: $gap-width,
        padding-left: $gap-width
      ));
    }
  }

  .posters__empty {
    @include rem((
      padding-right: $gap-width,
      padding-left: $gap-width
    ));
  }

  .content {
    @include rem((
      padding-right: $gap-width,
      padding-left: $gap-width
    ));
  }

  .content__section--right__inner {
    @media #{$mq-content-columns} {
      @include rem((
        padding-left: $gap-width,
        margin-left: $content-media-width
      ));
    }
  }

  .content__section--left {
    @media #{$mq-content-columns} {
      @include rem((width: $content-media-width));
    }
  }

  .content__thumbs {


    .media__list__item {


      @media #{$mq-no-sidebar} {
        @media #{$mq-no-content-columns} {
          @include size-thing($min-thumb-width, $gap-width * 2);
        }

        @media #{$mq-content-columns} {
          @include size-thing($min-thumb-width, $content-media-width + ($gap-width * 3));
        }
      }

      @media #{$mq-sidebar} {
        @include size-thing($min-thumb-width, $sidebar-width + $content-media-width + ($gap-width * 3));
      }
    }
  }

  .content__column {
    @include rem((
      padding-right: $gap-width / 2,
      padding-left: $gap-width / 2
    ));

    @media (max-width: nth($medium-range, 2)) {
      padding: 0;
    }

    .media__list__item {
      @media #{$mq-no-sidebar} {
        @include size-thing($min-thumb-width, $gap-width * 2);

        @media #{$large-up} {
          @include size-thing($min-thumb-width, $gap-width * 3, 2);
        }
      }

      @media #{$mq-sidebar} {
        @include size-thing($min-thumb-width, $sidebar-width - ($gap-width * 3), 2);
      }
    }

    .media__list__item--large {
      width: 100%;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

@include size-page(0, 60px, 20px, 200px);

@media (min-width: em(500px)) {
  @include size-page(0, 90px, 26px, 180px);
}

@media (min-width: em(660px)) {
  @include size-page(0, 90px, 26px, 250px);
}

@media (min-width: em(920px)) {
  @include size-page(350px, 100px, 32px, 375px);
}

@media (min-width: em(1380px)) {
  @include size-page(380px, 105px, 35px, 420px);
}


////////// recite /////////

.js-reciteMe{
width: 173px;
padding: 0;
background: none;
border: 0;
border-radius: 0;
transition: opacity 100ms ease;
  &:hover {
    opacity: 0.7;
    @supports (-webkit-filter: grayscale(1)) or (filter: grayscale(1)) {
      opacity: 1;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }
}
