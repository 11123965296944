.content {}

.content__heading {
  @include heading--level-2;
  @include rem((font-size: 42px));

  @media #{$mq-inline-header} {
    @include rem((margin-top: 5px));
  }
}

.content__subheading {
  @include rem((font-size: 22px));
  font-weight: 600;

  @media #{$mq-content-columns} {
    @include rem((margin-bottom: 16px));
  }
}

.content__group {
  @include clearfix;
  border-top: 1px solid $light;

  @include size-block(small) {
    @include rem((
      padding-top: $block-spacing-y,
      padding-bottom: $block-spacing-y
    ));
  }
}

.content__upper {
  @media #{$mq-no-content-columns} {
    display: flex;
    flex-direction: column;
    border-top: none;
  }
}

.content__group__heading {
  @include heading--level-2;
  margin-bottom: 0.67em;
}

.content__section--left {
  @media #{$mq-no-content-columns} {
    @include rem((margin-bottom: 21px));
    order: 1;
  }

  @media #{$mq-content-columns} {
    float: left;
    margin-left: -100%;
  }
}

.content__section--right {
  min-height: 1px;

  @media #{$mq-no-content-columns} {
    order: 2;
  }

  @media #{$mq-content-columns} {
    float: left;
    width: 100%;
  }
}

.content__section--right__inner {
  .content__section--right:last-child & {
    padding-left: 0;
    margin-left: 0;
  }
}

.content__media {
  @include rem((
    padding-top: $type-alignment-compensation,
    padding-bottom: $type-alignment-compensation
  ));

  @media #{$mq-no-content-columns} {
    overflow: hidden;
  }

  .aspect-ratio {
    @media #{$mq-no-content-columns} {
      margin-top: -10%;
      margin-bottom: -10%;
    }

    &:before {
      display: block;
      content: '';
      padding-top: 100%;
    }
  }
}

.content__media__image {}

.content__media__caption {
  @include sentinel-italic;
  @include small;
  @include rem((padding-top: 7px));
  text-align: center;
  color: $mid;
}

.content__description {
  @include rem((max-width: $max-content-width));
}

.content__thumbs {
  @include rem((margin-top: 12px));

  @media #{$large-up} {
    @include rem((margin-top: 18px));
  }
}

.content__column {


  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
